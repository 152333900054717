<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{
              $t("message.new_m", {
                m: $t("message.client_additional"),
              })
            }}</p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">

            <el-form ref="form" :model="form" :rules="rules" label-width="120px" label-position="top">
              
              <el-form-item :label="$t('message.label')" prop="column_name">
                <el-input v-model="form.column_name" clearable></el-input>
              </el-form-item>
              
              <el-form-item :label="$t('message.Type')" prop="column_type">
                <el-select class="w-100" v-model="form.column_type"  @change="changeType" :placeholder="$t('message.Type')" clearable>
                  <el-option :label="$t('message.Input')" value="el-input"></el-option>
                  <el-option :label="$t('message.Textarea')" value="textarea"></el-option>
                  <el-option :label="$t('message.InputNumber')" value="el-input-number"></el-option>
                  <el-option :label="$t('message.Select')" value="el-select"></el-option>
                  <el-option :label="$t('message.Radio')" value="el-radio"></el-option>
                  <el-option :label="$t('message.Checkbox')" value="el-checkbox"></el-option>
                  <el-option :label="$t('message.Switch')" value="el-switch"></el-option>
                  <el-option :label="$t('message.DatePicker')" value="el-date-picker"></el-option>
                  <!-- <el-option :label="$t('message.Upload')" value="el-upload"></el-option> -->
                </el-select>
              </el-form-item>
              
              <div v-if="form.column_type && ['el-select', 'el-radio', 'el-checkbox'].includes(form.column_type)">
                <h3> {{ $t('message.options') }} </h3>

                <el-row v-for="(column_option, index) in form.column_options" :gutter="20">
                    <el-form-item
                      :prop="'column_options.' + index"
                      :rules="{ required: true, message: $t('message.please_enter_input', {input: $t('message.label')}), trigger: 'change' }"
                    >
                      <el-col :span="20">
                        <el-input v-model="form.column_options[index]"></el-input>
                      </el-col>
                      <el-col :span="4">
                        <el-button type="warning" @click="removeRow(index, false)" icon="el-icon-delete"></el-button>
                      </el-col>
                    </el-form-item>
                </el-row>
                <el-button class="mt-2" type="success" @click="addNewOption()">{{ $t('message.addNewOption') }}</el-button>
              </div>
              
              
              <el-row :gutter="20" v-if="form.column_type == 'el-switch'">
                <el-col :span="12">
                  <el-input :placeholder="$t('message.is_active') + ' ' + $t('message.text_t')" v-model="form.column_options[0]" clearable></el-input>
                </el-col>
                <el-col :span="12">
                  <el-input :placeholder="$t('message.inactive') + ' ' + $t('message.text_t')" v-model="form.column_options[1]" clearable></el-input>
                </el-col>
              </el-row>
            </el-form>

        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";

export default {
  
  mixins: [form, drawer],
  data() {
    return {

    };
  },
  created() {},
  computed: {
      ...mapGetters({
          rules: "clientAdditional/rules",
          model: "clientAdditional/model",
          columns: "clientAdditional/columns",
      }),
  },
  methods: {
      ...mapActions({
          save: "clientAdditional/store",
      }),
      changeType(type){
        if (type == 'textarea'){
          this.form.props = {type: "textarea", autosize: true};
        }
        else if (type == 'el-input-number'){
          this.form.props = {min: 0};
        }
        else if (type == 'el-select'){
          this.form.props = {size: 'large', class: 'w-100'};
        }
        else if (type == 'el-date-picker'){
          this.form.props = {type: 'date', 'value-format': 'yyyy-MM-dd', format: 'yyyy-MM-dd'};
        }
        else if (type == 'el-checkbox'){
          this.form.props = {border: true, is_multiple_choice: true};
        }
        else if (type == 'el-radio'){
          this.form.props = {border: true,  is_loop: true, is_multiple_choice: false};
        }
      },

      addNewOption(){
        this.form.column_options.push('');
      },
      removeRow(index){
        if (this.form.column_options.length > 0) this.form.column_options.splice(index, 1);
      },

      submit(close = true) {
          if(this.form.column_type == 'textarea'){
            this.form.column_type = 'el-input';
          }
          this.$refs["form"].validate((valid) => {
              if (valid) {
                  this.loadingButton = true;
                  this.save(this.form)
                      .then((res) => {
                          this.loadingButton = false;
                          this.$alert(res);
                          this.parent().listChanged();
                          if (close) this.close();
                      })
                      .catch((err) => {
                          this.loadingButton = false;
                          this.$alert(err);
                      });
              }
          });
      },
  },
};
</script>
